<template>
  <v-container>
    <v-row
      justify="center"
      align="center"
    >
      <v-col
        cols="2"
      >
        <v-icon class="text-center">
          {{ icon }}
        </v-icon>
      </v-col>
      <v-col
        cols="10"
      >
        <div class="font-weight-bold">
          {{ city }}
        </div>
        <div class="">
          <small>{{ date }}</small>
        </div>
        <div class="">
          <small>{{ hour }}</small>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'EventsData',
    inject: ['theme'],

    props: {
      city: String,
      date: String,
      hour: String,
      icon: String,
    },

    data: () => ({}),

    computed: {},
  }
</script>

<style>
</style>
