import WebFontLoader from 'webfontloader'

// async load fonts
WebFontLoader.load({
  google: {
    families: [
      'Montserrat:400,900&display=swap',
    ],
  },
})
