// Vuetify Documentation https://vuetifyjs.com

import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#0D285C',
        secondary: '#505050',
        accent: '#204165',
        bgAppBar: '#00205d',
        info: '#65b363',
        success: '#64b363',
        danger: '#F44336',
      },
      dark: {
        primary: '#00205d',
        secondary: '#00205C',
        accent: '#204165',
      },
    },
  },
})
